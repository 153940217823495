import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography } from "@mui/material";

import { CancellationPolicyMenu } from "../../Assets/Data";


//images
import cancellationImg1 from "../../Assets/Images/cancellation.png";
import redTikIcon from "../../Assets/Images/redTikIcon.png";
import cancellationBgImg2 from "../../Assets/Images/cancellationBgImg2.png";
import { checkIcon } from "../../Assets/Data";
import dropIcon from "../../Assets/Images/DropI_icon.png";
import sideArrow from "../../Assets/Images/sideMenuArrowBtn.png";


export default function CancellationPolicy({ currentPolicyPage }) {
  const [activeSection, setActiveSection] = useState(null);
  const CancellationPolicySection = useRef([]);
  const [sideMenu, setSideMenu] = useState(false);


  const handleMenuClick = (index) => {
    CancellationPolicySection?.current[index]?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 1000)
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    CancellationPolicySection.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      CancellationPolicySection.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);


  return (
    <>
      <Box className="policyPageSection">
        <Box className={sideMenu ? "policySideSection sideMenuActive sideMenuPadding" : "policySideSection sideMenuDeactive"}>
          <img style={{ left: sideMenu ? "41%" : "-6%", rotate: sideMenu ? "0deg" : "180deg" }} src={sideArrow} className='sideArrowBtn' onClick={() => setSideMenu(!sideMenu)} />

          {CancellationPolicyMenu?.map((el, i) => (
            activeSection === `section-${i}` ?
              <Box className="sideMenuDropBtn" sx={{ minHeight: el.length > 25 ? "57px" : "38px" }}>
                <img src={dropIcon} />
                <Typography>{el.length > 44 ? `${el.slice(0, 44)}...` : el}</Typography>
              </Box>
              :
              <Box className="sideMenuItemBox" onClick={() => handleMenuClick(i)} key={i}>
                {checkIcon}
                <Typography className={activeSection === `section-${i}` ? "policyNavActiveText" : "policyNavText"} >{el.length > 44 ? `${el.slice(0, 44)}...` : el}</Typography>
              </Box>
          ))}
        </Box>
        <Box pb={15} className="policyMainSection">
          <Typography className="policyHeader" id="section-0" ref={(el) => (CancellationPolicySection.current[0] = el)}>
            Cancellation <span>Policy</span>
          </Typography>
          <Typography className="policyNText">
            We recognize the thrill of embarking on a trek, exploring new
            landscapes, and immersing yourself in adventure. However, life can
            be unpredictable, and unforeseen situations may force you to
            reconsider your plans. That’s why we’ve carefully crafted a
            cancellation and refund policy that prioritizes your well-being
            and peace of mind.
          </Typography>

          <Typography className="policyHeader1">
            Our Trekker-Friendly Cancellation Policy
          </Typography>

          <Typography className="policyHeader2">
            Understanding Your Situation
          </Typography>
          <Typography className="policyNText">
            We empathize with the disappointment of having to cancel a
            long-awaited trek. Whether it’s due to personal reasons, health
            concerns, or external factors, we’re here to support you
          </Typography>
          <Box className="gapBox"></Box>
          <Typography className="policyHeader2">
            Simplicity and Transparency
          </Typography>
          <Typography className="policyNText">
            Our policy is straightforward and easy to grasp. No convoluted
            terms or hidden clauses. We believe in keeping things simple for
            our valued guests.
          </Typography>
          <Box className="gapBox"></Box>
          <Typography className="policyHeader2">
            Your Adventure Awaits
          </Typography>
          <Typography className="policyNText">
            While cancellations are never ideal, we encourage you to look
            forward to future adventures
          </Typography>

          <Box className="policyCenterImgBox">
            <img src={cancellationImg1} />
          </Box>

          <Typography id="section-1" ref={(el) => (CancellationPolicySection.current[1] = el)} className="policyHeader">
            Cancellation policy for treks that <br /> are{" "}
            <span>less than 2 days/1 Night</span>{" "}
          </Typography>

          <Box className="redioTextLine">
            <img src={redTikIcon} />
            <Typography>
              Cancel 30 days before the trek: <span>100% refund</span>
            </Typography>
          </Box>
          <Box className="redioTextLine">
            <img src={redTikIcon} />
            <Typography>
              Cancel between 29 and 16 days before the trek:{" "}
              <span>90% refund</span>
            </Typography>
          </Box>
          <Box className="redioTextLine">
            <img src={redTikIcon} />
            <Typography>
              Cancel between 15 and 11 days before the trek:{" "}
              <span>50% refund</span>
            </Typography>
          </Box>
          <Box className="redioTextLine">
            <img src={redTikIcon} />
            <Typography>
              Cancel between 10 and 8 days before the trek:{" "}
              <span>30% refund</span>
            </Typography>
          </Box>
          <Box className="redioTextLine">
            <img src={redTikIcon} />
            <Typography>
              Cancel 7 days before the trek: <span>No refund</span>
            </Typography>
          </Box>
          <Box className="redioTextLine">
            <img src={redTikIcon} />
            <Typography>
              If you cannot attend the trek for any reason:{" "}
              <span>No refund</span>
            </Typography>
          </Box>

          <Typography className="policyNText">
            BookmyTrail will give an option to transfer the ticket without
            losing any money, provided the trek Partner (Trek Organiser) has
            not booked any Flight/Train ticket.{" "}
          </Typography>

          <Box my={10} className="policyCenterImgBox">
            <img src={cancellationBgImg2} />
          </Box>

          <Typography id="section-2" ref={(el) => (CancellationPolicySection.current[2] = el)} className="policyHeader">
            Cancellation policy for treks that are more{" "}
            <span>than 3 days/2 Nights</span> or Himalayan treks{" "}
          </Typography>

          <Box className="redioTextLine">
            <img src={redTikIcon} />
            <Typography>
              Cancel 30 days before the trek: <span>90% refund</span>
            </Typography>
          </Box>
          <Box className="redioTextLine">
            <img src={redTikIcon} />
            <Typography>
              Cancel between 29 and 16 days before the trek:{" "}
              <span>50% refund</span>
            </Typography>
          </Box>
          <Box className="redioTextLine">
            <img src={redTikIcon} />
            <Typography>
              Cancel between 15 and 11 days before the trek:
              <span>30% refund</span>
            </Typography>
          </Box>
          <Box className="redioTextLine">
            <img src={redTikIcon} />
            <Typography>
              Cancel 10 days before the trek: <span>No refund</span>
            </Typography>
          </Box>
          <Box className="redioTextLine">
            <img src={redTikIcon} />
            <Typography>
              If you cannot attend the trek for any reason:
              <span>No refund</span>
            </Typography>
          </Box>

          <Typography className="policyNText">
            BookmyTrail will give an option to transfer the ticket without
            losing any money, provided the trek Partner (Trek Organiser) has
            not booked any Flight/Train ticket.
          </Typography>
          <Typography className="policyNText">
            For a group booking, BookMyTrail provides option to cancel
            selected trekkers or the complete group. The above policy is still
            applicable.
          </Typography>
          <Typography className="policyNText">
            All the refunded money will be moved to your wallet and can be
            used for future transactions.
          </Typography>
        </Box>
      </Box>
    </>
  )
}
