import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import "./style.css";
import { useTranslation } from 'react-i18next';

//Images
import Clogo from "../../Assets/Images/Clogo.png";
import TMIcon from "../../Assets/Images/tmIcon.png";
import { Box, Typography } from '@mui/material';
import callIcom from "../../Assets/Images/callIcon.png";
import whatsapIcom from "../../Assets/Images/whatsappIcon.png";
import dropIcon from "../../Assets/Images/dropIcon.png";
import HamMenuIcon from "../../Assets/Images/humMenuIcon.png";
import backIocn from "../../Assets/Images/backIcon.png"


export default function NavBar({ langDropVal, setLanDropVal }) {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const [langDrop, setLanDrop] = useState(false);
  const [sideMenu, setSideMenu] = useState(false)

  const langData = [
    "English",
    "Hindi",
    "Kannada"
  ];

  const handelChangeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    if (sideMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    document.addEventListener('mousedown', (e) => {
      if (e.target.id != "lanPop") {
        setLanDrop(false)
      }
    })
  }, [sideMenu])

  return (
    <>
      <Box className="navBar">
        <Box className="sideMenu" sx={{ width: sideMenu ? "100%" : "0%" }}>
          <img src={backIocn} className='backIcon' onClick={() => setSideMenu(false)} />

          <Box id="lanPop" className="lanDrop" onClick={() => setLanDrop(!langDrop)}>
            <Typography id="lanPop">{langDropVal}</Typography>
            <img id="lanPop" src={dropIcon} className='lanDropIcon' style={{ rotate: langDrop ? "0deg" : "-180deg" }} />
            <Box id="lanPop" className="dropBox" sx={{ height: langDrop ? "120px" : "0px", border: langDrop ? "1px solid #C4C4C4" : "none" }}>
              {
                langData?.map((el, i) => (
                  <Box id="lanPop" key={i} className="dropItem" onClick={() => {
                    handelChangeLanguage(el)
                    setLanDropVal(el)
                    setSideMenu(false)
                  }} >
                    <Typography id="lanPop">{el}</Typography>
                  </Box>
                ))
              }
            </Box>
          </Box>

        </Box>

        <Box className='ClogoBox'>
          <img className='cLog' src={Clogo} onClick={() => navigate("/")} />
          <img src={TMIcon} className='tmIcon' />
        </Box>

        <Box className="humMenuBox">
          <img src={HamMenuIcon} className='humIcon' onClick={() => setSideMenu(true)} />
        </Box>
        <Box className='navNdBox'>

          <Box className="navItem">
            <Box className="NIconBox">
              <img src={callIcom} />
            </Box>
            <Box>
              <Typography>{t("navText.callUs")}</Typography>
              <Typography>99999999</Typography>
            </Box>
          </Box>

          <Box className="navItem">
            <Box className="NIconBox">
              <img src={whatsapIcom} />
            </Box>
            <Box>
              <Typography>{t("navText.WhatsApp")}</Typography>
              <Typography>99999999</Typography>
            </Box>
          </Box>

          <Box id="lanPop" className="lanDrop" onClick={() => setLanDrop(!langDrop)}>
            <Typography id="lanPop">{langDropVal}</Typography>
            <img id="lanPop" src={dropIcon} className='lanDropIcon' style={{ rotate: langDrop ? "0deg" : "-180deg" }} />
            <Box id="lanPop" className="dropBox" sx={{ height: langDrop ? "120px" : "0px", border: langDrop ? "1px solid #C4C4C4" : "none" }}>
              {
                langData?.map((el, i) => (
                  <Box id="lanPop" key={i} className="dropItem" onClick={() => {
                    handelChangeLanguage(el)
                    setLanDropVal(el)
                  }}>
                    <Typography id="lanPop">{el}</Typography>
                  </Box>
                ))
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
