import React, { useState } from 'react'
import "./style.css";
import { Box, Typography } from '@mui/material';
import { sendOTP } from "../../utility/SendOTP"

export default function ChangeNumPop({ changeNumPop, setChangeNumPop, setNum, num, setOtpPop, setExpTime, OTPValidTime }) {

  const handlePopClose = (e) => {
    if (e?.target?.id === "backDrop") {
      setChangeNumPop(false)
    }
  }

  const handelSendOTP = () => {
    sendOTP({ num })
    setChangeNumPop(false)


    setOtpPop(true)
    setExpTime(OTPValidTime)


  }

  return (
    <>
      <Box id="backDrop" onClick={handlePopClose} className="backdrop" sx={{ display: changeNumPop ? "flex" : "none" }}>
        <Box className="popContainer" sx={{ display: changeNumPop ? "flex" : "none" }}>
          <Typography mb={2} className='popHeader'>Change Number</Typography>
          <input type="number" className='chNumberInput' placeholder='Enter Your Number' onChange={(e) => setNum(e.target.value)} value={num} />
          <Box className="appBtn" onClick={handelSendOTP}>
            <Typography>Send</Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}
