import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography } from "@mui/material";

//images
import policyVerifyImg from "../../Assets/Images/policyVerifyImg.png";
import policyKeyImg from "../../Assets/Images/policyKeyImg.png";
import dropIcon from "../../Assets/Images/DropI_icon.png";
import { checkIcon } from "../../Assets/Data";
import sideArrow from "../../Assets/Images/sideMenuArrowBtn.png";




//data
import { PrivacyMenuItem } from "../../Assets/Data";

export default function PrivacyPolicy() {
  const [activeSection, setActiveSection] = useState(null);
  const PrivacyPolicySection = useRef([]);
  const [sideMenu, setSideMenu] = useState(false);


  const handleMenuClick = (index) => {
    PrivacyPolicySection?.current[index]?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 1000)
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    PrivacyPolicySection.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      PrivacyPolicySection.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <>
      <Box className="policyPageSection PrivacyPage">
        <Box className={sideMenu ? "policySideSection sideMenuActive sideMenuPadding" : "policySideSection sideMenuDeactive"}>
          <img style={{ left: sideMenu ? "41%" : "-6%", rotate: sideMenu ? "0deg" : "180deg" }} src={sideArrow} className='sideArrowBtn' onClick={() => setSideMenu(!sideMenu)} />

          {PrivacyMenuItem?.map((el, i) => (
            activeSection === `section-${i}` ?
              <Box className="sideMenuDropBtn" sx={{ minHeight: el.length > 25 ? "57px" : "38px" }}>
                <img src={dropIcon} />
                <Typography>{el.length > 44 ? `${el.slice(0, 44)}...` : el}</Typography>
              </Box>
              :
              <Box className="sideMenuItemBox" onClick={() => handleMenuClick(i)} key={i}>
                {checkIcon}
                <Typography className={activeSection === `section-${i}` ? "policyNavActiveText" : "policyNavText"} >{el.length > 44 ? `${el.slice(0, 44)}...` : el}</Typography>
              </Box>
          ))}
        </Box>

        <Box pb={15} className="policyMainSection">
          <Typography textAlign={"center"} className="policyHeader" id="section-0" ref={(el) => (PrivacyPolicySection.current[0] = el)}>
            <span>Privacy</span> Notice
          </Typography>
          <Typography className="lastUpdateText">
            Last updated <span>23/07/2024</span>
          </Typography>

          <Typography mt={3} className="policyNText">
            This privacy notice for______(“we,” “us,” or “our”), describes how
            and why we might collect, store, use, and/or share (“process”)
            your information when you use our services (“Services”), such as
            when you:
          </Typography>
          <Typography className="policyHeader2">
            Questions or concerns?{" "}
          </Typography>
          <Typography className="policyNText">
            Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and
            practices, please do not use our Services
          </Typography>

          <Box my={5} className="policyCenterImgBox">
            <img src={policyVerifyImg} />
          </Box>

          <Typography id="section-1" ref={(el) => (PrivacyPolicySection.current[1] = el)} className="policyColorHeader2">
            Summary of <span> Key points</span>
          </Typography>
          <Typography className="policyNText">
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking
            the link following each key point or by using our table of
            contents below to find the section you are looking for.
          </Typography>

          <Typography className="policyHeader2">
            What personal information do we process?{" "}
          </Typography>
          <Typography className="policyNText">
            When you visit, use, or navigate our Services, we may process
            personal information depending on how you interact with us and the
            Services, the choices you make, and the products and features you
            use. Leam more about personal information you disclose to us.
          </Typography>

          <Typography className="policyHeader2">
            Do we process any sensitive personal information?{" "}
          </Typography>
          <Typography className="policyNText">
            We do not process sensitive personal information.
          </Typography>

          <Typography className="policyHeader2">
            Do we collect any information from third parties?{" "}
          </Typography>
          <Typography className="policyNText">
            We may collect information from public databases, marketing
            partners, social media platforms, and other outside sources. Learn
            more about information collected from other sources
          </Typography>

          <Typography className="policyHeader2">
            How do we process your information?{" "}
          </Typography>
          <Typography className="policyNText">
            We process your information to provide, improve, and administer
            our Services, communicate with you, for security and fraud
            prevention, and to comply with law. We may also process your
            information for other purposes with your consent. We process your
            information only when we have a valid legal reason to do so. Learn
            more about how we process your information.
          </Typography>

          <Typography className="policyHeader2">
            In what situations and with which parties do we share personal
            information?
          </Typography>
          <Typography className="policyNText">
            We may share information in specific situations and with specific
            third parties. Learn more about when and with whom we share your
            personal information.
          </Typography>

          <Typography className="policyHeader2">
            What are your rights?{" "}
          </Typography>
          <Typography className="policyNText">
            Depending on where you are located geographically, the applicable
            privacy law may mean you have certain rights regarding your
            personal information. Learn more about your privacy rights
          </Typography>

          <Typography className="policyHeader2">
            How do you exercise your rights?{" "}
          </Typography>
          <Typography className="policyNText">
            The easiest way to exercise your rights is by submitting a data
            subject access request, or by contacting us. We will consider and
            act upon any request in accordance with applicable data protection
            laws.
          </Typography>

          <Typography className="policyNText">
            Want to learn more about what we do with any information we
            collect? Review the privacy notice in full.
          </Typography>

          <Box my={5} className="policyCenterImgBox">
            <img src={policyKeyImg} />
          </Box>

          <Typography className="policyHeader1" id="section-2" ref={(el) => (PrivacyPolicySection.current[2] = el)}>
            1. What Information Do We Collects?
          </Typography>
          <Typography className="policyHeader2">
            Personal information you disclose to us
          </Typography>

          <Typography className="policyNText">
            Want to learn more about what we do with any information we
            collect? Review the privacy notice in full.
          </Typography>

          <Typography className="inShotText">
            <span>In Short</span> We collect personal information that you
            provide to us
          </Typography>

          <Typography className="policyNText">
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in
            obtaining information about us or our products. And Services, when
            you participate in activities on the Services, or otherwise when
            you contact us.
          </Typography>

          <Typography className="policyHeader2">
            Sensitive Information
          </Typography>
          <Typography className="policyNText">
            We do not process sensitive information.
          </Typography>
          <Typography className="policyNText">
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information
          </Typography>

          <Typography className="policyHeader2">
            Information automatically collected
          </Typography>
          <Typography className="inShotText">
            <span>In Short</span> Some information such as your Internet
            Protocol (IP) address and/or browser and device characteristics is
            collected automatically when you visit our Services.
          </Typography>

          <Typography className="policyNText">
            We automatically collect certain information when you visit, use,
            or navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </Typography>

          <Typography className="policyNText">
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </Typography>

          <Box className="gapBox"></Box>
          <Typography className="policyHeader1" id="section-3" ref={(el) => (PrivacyPolicySection.current[3] = el)}>
            2. How Do We Process Your Information?
          </Typography>
          <Typography className="policyHeader2">
            Personal information you disclose to us{" "}
          </Typography>
          <Typography className="inShotText">
            <span>In Short</span> We process your information to provide,
            improve, and administer our Services, communicate with you, for
            security and fraud prevention, and to comply with law. We may also
            process your information for other purposes with your consent.
          </Typography>
          <Typography className="policyNText">
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including
          </Typography>

          <Box className="gapBox"></Box>
          <Typography className="policyHeader1" id="section-4" ref={(el) => (PrivacyPolicySection.current[4] = el)}>
            3. When And With Whom Do We Share Your Personal Information?{" "}
          </Typography>
          <Typography className="inShotText">
            <span>In Short</span> We may share information in specific
            situations described in this section and/or with the following
            third partiesWe may need to share your personal information in
            the following situations:
          </Typography>

          <Typography className="policyHeader2">Business Transfers</Typography>

          <Typography className="policyNText">
            are or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets,
            financing. Or acquisition of all or a portion of our business t
            another company. </Typography>

          <Typography className="policyHeader2">Affiliates</Typography>

          <Typography className="policyNText">
            We may share your information with our affiliates, in which case
            we will require those affiliates to honor this privacy notice.
            Affiliates include our parent company and any subsidiaries, joint
            venture partners, or other companies that we control or that are
            der common control with us.
          </Typography>

          <Typography className="policyHeader2">Business Partners</Typography>
          <Typography className="policyNText">We may share your information with our business partners to offer you certain products, services, or promotions </Typography>


          <Box className="gapBox"></Box>
          <Typography className="policyHeader1" id="section-5" ref={(el) => (PrivacyPolicySection.current[5] = el)}>4. Do We Use Cookies And Other Tracking Technologies? </Typography>

          <Typography className="inShotText">
            <span>  In Short</span> We may use cookies and other
            tracking technologies to collect and store your information.
          </Typography>

          <Typography className="policyNText">
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to gather information when you interact with
            our Services. Some online tracking technologies help us maintain
            the security of our Services, prevent crashes, fix bugs, save your
            preferences, and assist with basic site functions.
          </Typography>

          <Typography className="policyNText">
            We also permit third parties andservice providers to use online
            tracking technologies on our Services for analytics and
            advertising, including to help manage and display advertisements,
            to tailor advertisements  to your interests, or to send abandoned
            shopping cart reminders (depending on your communication
            preferences). The third parties and service providers use their
            technology to provide advertising about products and services
            tailored to your interests which may appear either on our Services
            or on other websites.
          </Typography>

          <Typography className="policyNText">
            Specific information about how we use such technologies and how
            you  can refuse certain cookies is set out in our Cookie Notice.
          </Typography>

          <Box className="gapBox"></Box>
          <Typography className="policyHeader1" id="section-6" ref={(el) => (PrivacyPolicySection.current[6] = el)}>5. How Do We Handle Your Social Logies?</Typography>

          <Typography className="inShotText">
            <span>  In Short</span> If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.
          </Typography>

          <Typography className="policyNText">Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or X logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.</Typography>
          <Typography className="policyNText">We will use the information we receive anly for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.</Typography>

          <Box className="gapBox"></Box>
          <Typography className="policyHeader1" id="section-7" ref={(el) => (PrivacyPolicySection.current[7] = el)}>6. Is your information transferred internationally?</Typography>
          <Typography className="inShotText"><span>  In Short</span> We may transfer, store, and process your information in countries other than your own.</Typography>
          <Typography className="policyNText">Our servers are located in. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see “WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?” above), in and other countries.</Typography>
          <Typography className="policyNText">If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</Typography>

          <Box className="gapBox"></Box>
          <Typography className="policyHeader1" id="section-8" ref={(el) => (PrivacyPolicySection.current[8] = el)}>7. how long do we keep your information?</Typography>
          <Typography className="inShotText"><span>  In Short</span> We do not knowingly collect data from or market to children under 18 years of age.</Typography>
          <Typography className="policyNText">We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we leam that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at________</Typography>

          <Box className="gapBox"></Box>
          <Typography className="policyHeader1" id="section-9" ref={(el) => (PrivacyPolicySection.current[9] = el)}>8. what are your privacy rights?</Typography>
          <Typography className="inShotText"><span>  In Short</span> You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.</Typography>
          <Typography className="policyHeader2">Withdrawing your consent</Typography>
          <Typography className="policyNText">if we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?” Below.</Typography>
          <Typography className="policyNText">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent</Typography>

          <Typography className="policyHeader2">Account Information</Typography>
          <Typography className="policyNText">If you would at any time like to review or change the information in your account or terminate your account, you can:
            Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</Typography>

          <Box className="gapBox"></Box>
          <Typography className="policyHeader1" id="section-10" ref={(el) => (PrivacyPolicySection.current[10] = el)}>9. Controls for do-not-track features</Typography>
          <Typography className="policyNText">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice</Typography>

          <Box className="gapBox"></Box>
          <Typography className="policyHeader1" id="section-11" ref={(el) => (PrivacyPolicySection.current[11] = el)}>10. Do we make updates to this notice?</Typography>
          <Typography className="inShotText"><span>  In Short</span> Yes, we will update this notice as necessary to stay compliant with relevant laws</Typography>
          <Typography className="policyNText">We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date at the top of this privacy notice. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</Typography>

          <Box className="gapBox"></Box>
          <Typography className="policyHeader1" id="section-12" ref={(el) => (PrivacyPolicySection.current[12] = el)}>11. how can you contact us about this notice?</Typography>
          <Typography className="policyNText">If you have questions or comments about this notice, you may contact us by post at</Typography>
          <Typography className="policyNText">_____________________________________________</Typography>
          <Typography className="policyNText">_____________________________________________</Typography>
          <Typography className="policyNText">_____________________________________________</Typography>

          <Box className="gapBox"></Box>
          <Typography className="policyHeader1" id="section-13" ref={(el) => (PrivacyPolicySection.current[13] = el)}>12. How can you review, update, or delete the data we collect from you?</Typography>
          <Typography className="policyNText">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.</Typography>

        </Box>
      </Box>
    </>
  )
}
