export const CancellationPolicyMenu = [
  "Cancellation policy",
  "Cancellation policy for treks that are less than 2 days/1 Night ",
  "Cancellation policy for treks that are more than 3 days/2 Nights or Himalayan treks",
];

export const PrivacyMenuItem = [
  "Privacy Notice",
  "Summary Of Key Points?",
  "What information do we collect?",
  "How do we process your information?",
  "When and with whom do we share your info?",
  "Do we use cookies and other tracking ?",
  "How do we handle your social logins?",
  "Is your information transferred internationally?",
  "How long do we keep your information?",
  "What are your privacy rights?",
  "Controls for do-not-track features",
  "Do we make updates to this notice?",
  "How can you contact us about this notice?",
  "How can you review, update, or delete the data we collect from you?",
];

export const TermsMenuItem = [
  "Agreement To Our Legal Terms",
  "Our services",
  "Intellectual property rights",
  "User representations",
  "User registration",
  "Purchases and payment",
  "Subscriptions",
  "Policy",
  "Prohibited activities",
  "User generated contributions",
  "Contribution licence",
  "Guidelines for reviews",
  "Services management",
  "Privacy policy",
  "Copyright infringements",
  "Term and termination",
  "Modifications and interruptions",
  "Governing law",
  "Dispute resolution",
  "Corrections",
  "Disclaimer",
  "Limitations of liability",
  "Indemnification",
  "User data",
  "Electronic communications, transactions",
  "Sms text messaging",
  "Miscellaneous",
  "Trekking policy & partner",
  "Wallet guidelines & usage",
  "Partner eligibility criteria",
  "Contact us",
];

export const checkIcon = (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.49023 17.043C9.60647 17.043 10.7118 16.8231 11.743 16.3959C12.7743 15.9688 13.7113 15.3427 14.5006 14.5534C15.2899 13.7641 15.916 12.827 16.3432 11.7958C16.7704 10.7645 16.9902 9.6592 16.9902 8.54297C16.9902 7.42673 16.7704 6.32143 16.3432 5.29016C15.916 4.25889 15.2899 3.32186 14.5006 2.53256C13.7113 1.74326 12.7743 1.11716 11.743 0.689993C10.7118 0.262828 9.60647 0.0429687 8.49023 0.0429688C6.23589 0.0429688 4.07389 0.938502 2.47983 2.53256C0.885768 4.12662 -0.00976562 6.28863 -0.00976562 8.54297C-0.00976563 10.7973 0.885768 12.9593 2.47983 14.5534C4.07389 16.1474 6.23589 17.043 8.49023 17.043ZM8.27112 11.9807L12.9933 6.31408L11.5427 5.10519L7.48157 9.97758L5.38018 7.87525L4.04473 9.21069L6.87807 12.044L7.60907 12.775L8.27112 11.9807Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.49023 17.043C9.60647 17.043 10.7118 16.8231 11.743 16.3959C12.7743 15.9688 13.7113 15.3427 14.5006 14.5534C15.2899 13.7641 15.916 12.827 16.3432 11.7958C16.7704 10.7645 16.9902 9.6592 16.9902 8.54297C16.9902 7.42673 16.7704 6.32143 16.3432 5.29016C15.916 4.25889 15.2899 3.32186 14.5006 2.53256C13.7113 1.74326 12.7743 1.11716 11.743 0.689993C10.7118 0.262828 9.60647 0.0429687 8.49023 0.0429688C6.23589 0.0429688 4.07389 0.938502 2.47983 2.53256C0.885768 4.12662 -0.00976562 6.28863 -0.00976562 8.54297C-0.00976563 10.7973 0.885768 12.9593 2.47983 14.5534C4.07389 16.1474 6.23589 17.043 8.49023 17.043ZM8.27112 11.9807L12.9933 6.31408L11.5427 5.10519L7.48157 9.97758L5.38018 7.87525L4.04473 9.21069L6.87807 12.044L7.60907 12.775L8.27112 11.9807Z"
      fill="white"
    />
  </svg>
);
export const checkIconActive = (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.49023 17.043C9.60647 17.043 10.7118 16.8231 11.743 16.3959C12.7743 15.9688 13.7113 15.3427 14.5006 14.5534C15.2899 13.7641 15.916 12.827 16.3432 11.7958C16.7704 10.7645 16.9902 9.6592 16.9902 8.54297C16.9902 7.42673 16.7704 6.32143 16.3432 5.29016C15.916 4.25889 15.2899 3.32186 14.5006 2.53256C13.7113 1.74326 12.7743 1.11716 11.743 0.689993C10.7118 0.262828 9.60647 0.0429687 8.49023 0.0429688C6.23589 0.0429688 4.07389 0.938502 2.47983 2.53256C0.885768 4.12662 -0.00976562 6.28863 -0.00976562 8.54297C-0.00976563 10.7973 0.885768 12.9593 2.47983 14.5534C4.07389 16.1474 6.23589 17.043 8.49023 17.043ZM8.27112 11.9807L12.9933 6.31408L11.5427 5.10519L7.48157 9.97758L5.38018 7.87525L4.04473 9.21069L6.87807 12.044L7.60907 12.775L8.27112 11.9807Z"
      fill="#e4026e"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.49023 17.043C9.60647 17.043 10.7118 16.8231 11.743 16.3959C12.7743 15.9688 13.7113 15.3427 14.5006 14.5534C15.2899 13.7641 15.916 12.827 16.3432 11.7958C16.7704 10.7645 16.9902 9.6592 16.9902 8.54297C16.9902 7.42673 16.7704 6.32143 16.3432 5.29016C15.916 4.25889 15.2899 3.32186 14.5006 2.53256C13.7113 1.74326 12.7743 1.11716 11.743 0.689993C10.7118 0.262828 9.60647 0.0429687 8.49023 0.0429688C6.23589 0.0429688 4.07389 0.938502 2.47983 2.53256C0.885768 4.12662 -0.00976562 6.28863 -0.00976562 8.54297C-0.00976563 10.7973 0.885768 12.9593 2.47983 14.5534C4.07389 16.1474 6.23589 17.043 8.49023 17.043ZM8.27112 11.9807L12.9933 6.31408L11.5427 5.10519L7.48157 9.97758L5.38018 7.87525L4.04473 9.21069L6.87807 12.044L7.60907 12.775L8.27112 11.9807Z"
      fill="#e4026e"
    />
  </svg>
);
