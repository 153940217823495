import React, { useState, useEffect } from 'react';
import "./style.css"

import { Box, Typography } from '@mui/material';
import thankPopIcon from "../../Assets/Images/thankPopIcon.png";

import { sendEmail } from "../../utility/SendEmail";

export default function ThankPop({ thankPop, setThankPop, num }) {
  const [expTime, setExpTime] = useState(5);


  const handlePopClose = (e) => {
    if (e?.target?.id === "backDrop") {
      backHome()
    }
  }
  const backHome = () => {
    sendEmail(num)
    setThankPop(false)
    window.scrollTo({ top: 0 });
  }
  const calculateOpacity = () => {
    const minOpacity = 0.1;
    const maxOpacity = 0.9;
    return maxOpacity + ((minOpacity - maxOpacity) * (5 - expTime) / 5);
  };


  useEffect(() => {
    let interval;
    if (thankPop) {
      interval = setInterval(() => {
        setExpTime(prevExpTime => {
          if (prevExpTime > 0) {
            return prevExpTime - 1;
          } else {
            clearInterval(interval);
            backHome()
            return prevExpTime;
          }
        });

      }, 1000);
    }
    return () => clearInterval(interval);
  },);


  return (
    <>
      <Box id="backDrop" onClick={handlePopClose} className="backdrop" sx={{ display: thankPop ? "flex" : "none", opacity: thankPop ? calculateOpacity() : 1 }}>
        <Box className="popContainer thankPop" sx={{ display: thankPop ? "flex" : "none" }}>
          <img style={{ width: "124px" }} src={thankPopIcon} />
          <Typography mb={2} className='popHeader'>Thank You</Typography>
          <Typography className='nRotpText'>We will call you back</Typography>
          <Box className="appBtn" onClick={backHome}>
            <Typography className='otpExpText'> Back to home in {expTime}s</Typography>
            <Typography>Back to home</Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}
