import React, { useRef, useState, useEffect } from 'react'
import "./style.css"

import { Box, Typography } from '@mui/material'
import { sendOTP } from "../../utility/SendOTP"
import { toast } from 'react-toastify';


export default function OTPpop({ otpPop, setOtpPop, setChangeNumPop, setThankPop, expTime, setExpTime, OTPValidTime }) {
  const inputsRef = useRef([]);
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [error, setError] = useState(false)
  const varNumber = localStorage.getItem("varNumber")
  const [optSucced, setOtpSucced] = useState(false)



  const handelResendOTP = () => {
    toast.success("OTP sent successfully!")
    sendOTP({ num: varNumber })
    setExpTime(OTPValidTime)
  }

  const checkOTP = () => {
    const loOTP = localStorage.getItem("varValue")
    if (otp.join("") === loOTP) {
      setOtpSucced(true)
      setTimeout(() => {
        setThankPop(true)
        setOtpPop(false)
      }, 800);

    } else {
      setError(true)
    }
  }


  const handlePopClose = (e) => {
    if (e?.target?.id === "backDrop") {
      setOtpPop(false)
      window.location.reload()
    }
  }

  const handleNumPop = () => {
    setChangeNumPop(true)
    setOtpPop(false)
  }


  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) { // Ensure it's a single digit
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < 4 - 1) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      const newOtp = [...otp];
      if (otp[index]) {
        newOtp[index] = '';
        setOtp(newOtp);
      } else if (index > 0) {
        newOtp[index - 1] = '';
        setOtp(newOtp);
        inputsRef.current[index - 1].focus();
      }
    }
  };



  useEffect(() => {
    let interval;
    if (otpPop) {
      interval = setInterval(() => {
        setExpTime(prevExpTime => {
          if (prevExpTime > 0) {
            return prevExpTime - 1;
          } else {
            clearInterval(interval);
            window.location.reload()
            return prevExpTime;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [otpPop]);


  return (
    <>
      <Box id="backDrop" onClick={handlePopClose} className="backdrop" sx={{ display: otpPop ? "flex" : "none" }}>
        <Box className="popContainer" sx={{ display: otpPop ? "flex" : "none" }}>
          <Typography className='popHeader'>OTP Verification</Typography>
          <Typography className='popSubHeader'>Enter OTP code sent to {varNumber?.slice(0, 6)}***  <span onClick={handleNumPop}>Change Number</span></Typography>
          <div className='OtpBox'>
            <Typography className='otpExpText'>OTP valid for only {expTime}s</Typography>
            {otp.map((value, index) => (
              <input
                className={error ? 'otpInputErr' : optSucced ? 'otpInputSu' : ''}
                key={index}
                type='number'
                value={value}
                maxLength={1}
                ref={el => inputsRef.current[index] = el}
                onChange={e => handleInputChange(e, index)}
                onKeyDown={e => handleKeyDown(e, index)}
              />
            ))}
          </div>
          <span className='otpNM' style={{ display: error ? "block" : "none" }}>OTP does not match !</span>
          <Typography className='nRotpText'>Don’t receive OTP code?</Typography>
          <Typography className='rsOptText' onClick={handelResendOTP}>Resend Code</Typography>

          <Box className="appBtn" onClick={checkOTP}>
            <Typography>Verify</Typography>
          </Box>


        </Box>
      </Box>
    </>
  )
}
